<template>
  <div class="create-achievement-widget">
    <CForm
      v-on:submit.prevent="createWidget"
      class="create-achievement-widget-form"
      novalidate
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :isMainAction="!isHeaderActionsDisabled"
        :title="texts.widget.createPage.title"
        :descriptions="descriptions"
      />
      <!--   CONTENT   -->
      <div class="content">
        <div v-if="!isSelectTableVisible">
          <!--    FORM CONTENT   -->
          <div class="create-achievement-widget-form--fields">
            <!--   THEME    -->
            <ClFormRow
              :label="texts.widget.createPage.themeLabel"
              :toolTipText="descriptions.theme"
            >
              <template #content>
                <ClSelect
                  :options="themeOption"
                  :placeholder="texts.widget.createPage.themePlaceholder"
                  :value-prop="themeOption[0]"
                  size="xxl"
                  label-track="label"
                  track-by="value"
                  :is-press-btn="false"
                  @checkValue="updateTheme"
                />
              </template>
            </ClFormRow>
            <!-- NAME -->
            <ClFormRow
              :label="texts.widget.createPage.nameLabel"
              :toolTipText="descriptions.name"
            >
              <template #content>
                <CInput
                  :placeholder="texts.widget.createPage.namePlaceholder"
                  v-model="formData.name"
                />
              </template>
            </ClFormRow>
            <!--  Description -->
            <ClFormRow
              :label="texts.widget.createPage.descriptionLabel"
              :toolTipText="descriptions.description"
            >
              <template #content>
                <CTextarea
                  :placeholder="texts.widget.createPage.descriptionPlaceholder"
                  v-model="formData.description"
                  add-input-classes="col-sm-12"
                />
              </template>
            </ClFormRow>
            <!--     Achievements   -->
            <ClFormRow
              :label="texts.widget.createPage.achievementsLabel"
              tooltipPosition="label"
              xl-content="8"
              :toolTipText="descriptions.achievements"
            >
              <template #content>
                <CButton
                  variant="outline"
                  color="dark"
                  @click="toSelectTable"
                  class="wizard-action-btn zq--custom-button"
                >
                  {{ texts.widget.createPage.achievementsBtn }}
                </CButton>
                <ClTable
                  v-if="formData.achievements.length"
                  class="mt-2"
                  :items="formData.achievements"
                  :fields="fieldsPreview"
                  @delete="handleDeleteAchievement"
                />
              </template>
            </ClFormRow>
          </div>
        </div>
        <!--  SELECT ACH TABLE   -->
        <DepSelectTable
          v-if="isSelectTableVisible"
          @toResultTable="toResultTable"
          @updateSelectData="achSelectData"
          :selectedData="selectedData"
          :is-header="false"
          :fields="fields"
          :formData="formData"
          :toolTipText="descriptions.selectApiKey"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
// UTILS AND CONFIGS
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
// COMPONENTS
import CreateHeader from "@/shared/components/CreateHeader";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import DepSelectTable from "@/shared/components/DepTables/DepSelectTable";
import ClTable from './components/ClTable';
import {achievements} from "@/config/descriptions/achievements";
import {checkNullItem} from "@/helpers/checkNullItem";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  components: {
    CreateHeader,
    ClFormRow,
    ClSelect,
    ClTable,
    DepSelectTable,
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      // MOCK
      fields: [
        "select",
        "id",
        "name",
        "description",
        "created",
      ],
      //
      fieldsPreview: [
        '№',
        'id',
        'name',
        "description",
        "created",
        'actions'
      ],
      selectedData: [],
      isHeaderActionsDisabled: false,
      isSelectTableVisible: false,
      options: [],
      themeOption: [
        {
          value: 'defaultTheme',
          label: 'Default Theme',
        },
        {
          value: 'actionTheme',
          label: 'Action Theme',
        },
      ],
      formData: {
        theme: '',
        name: '',
        description: '',
        achievements: [],
      },
      descriptions: {
        ...achievements.widgets.create
      },
    }
  },
  computed: {
    ...mapGetters('achievements', ['achievements', 'loading'])
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleSetActiveTab', 'handleResetActiveTab']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
      // SET ACTIVE TAB
      this.handleSetActiveTab(1);
    },
    // ACH SELECT
    toSelectTable() {
      this.isHeaderActionsDisabled = true;
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isHeaderActionsDisabled = false;
      this.isSelectTableVisible = false;
    },
    achSelectData({selectedData}) {
      this.selectedData = checkNullItem(selectedData);
      this.formData.achievements = selectedData;
    },
    handleDeleteAchievement(id) {
      const newData = this.selectedData.filter((item) => item.id !== id);
      this.selectedData = checkNullItem(newData);
      this.formData.achievements = newData;
    },
    //
    updateTheme({value}) {
      this.formData.theme = value;
    },
    // CREATE WIDGET
    createWidget() {
      console.log(this.formData)
    }
  },
  destroyed() {
    // this.handleResetActiveTab();
  }
}
</script>

<style lang="scss">
.create-achievement-widget {
  height: 100%;

  .create-achievement-widget-form {
    height: calc(100% - 50px);

    .content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
    }
  }
}
</style>